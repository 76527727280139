<template>

  <router-link
      :to="$linkCreator($i18n.locale, link)"
      exact
      active-class="current"
      :class="{ 'mobile': isMobile, dark: $store.state.Index.isDarkMode }"
  >
    <svg v-html="icon" />
    <span>{{ name }}</span>
  </router-link>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DashboardMenuLink.vue",
  props: {
    name: String,
    link: String,
    icon: String
  },
  computed: {
    ...mapState('Index', [
      'isMobile'
    ])
  }
}
</script>

<style lang="scss" scoped>

a.dark:hover {
  background-color: rgb(42, 46, 47);
}

a {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(10,15,56,.6);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.41176rem;
  letter-spacing: .01em;
  border-radius: 10px;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out;
  transition: .25s ease-out;
  text-decoration: none;
  outline: 0;
  margin: 10px 0px;
  white-space: nowrap;

  &:hover {
    background-color: #2A85FF;
    color: white !important;

    svg {
      filter: brightness(0) invert(1);
    }
  }

  &.current {
    color: white;
    background: #2A85FF;

    svg {
      filter: brightness(0) invert(1);
    }

  }

  &.mobile {
    margin: 4px 12px;
    padding: 5px 10px;
  }

}

svg {
  display: flex;
  width: 30px;
  height: 30px;
  position: relative;
  top: 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

</style>