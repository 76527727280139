<template>
  <div>

    <div class="desktop-container" :class="{ dark: $store.state.Index.isDarkMode }" v-if="!isMobile">
      <article>
        <img :src="$store.state.Index.logo" alt="" />
        <router-link class="logo-name" :to="$linkCreator($i18n.locale, '/')">{{ websiteName.short }}</router-link>
      </article>

      <DashboardMenu :is-child="backButton" :link-array="linkArray" />
    </div>

    <div class="mobile-container" v-else>
      <DashboardMenu :is-child="backButton" :link-array="linkArray" />
    </div>


  </div>
</template>

<script>
import DashboardMenu from "@/components/main/DashboardMenu";
import {mapState} from "vuex";

export default {
  name: "DashboardMenuContainer",
  components: {
    DashboardMenu
  },
  props: {
    linkArray: Array,
    backButton: Boolean
  },
  computed: {
    ...mapState('Index', [
      'isAuthed',
      'userInfo',
      'websiteName',
      'isMobile'
    ])
  }
}
</script>

<style lang="scss" scoped>

@media screen and (max-width: 1600px) {
  .desktop-container {
    max-width: 240px !important;
  }
}

@media screen and (max-width: 1440px) {
  .desktop-container {
    max-width: 240px !important;
  }
}


.desktop-container {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 240px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out;
  transition: .25s ease-out;

  article {
    display: flex;
    align-items: center;
    margin: 0 10px;
    text-transform: capitalize;
    font-size: 19px;
    padding: 14px;
    height: 60px;

    img {
      height: 32px;
      margin-right: 5px;
    }

    a {
      text-decoration: none;
      font-weight: 400;
    }
  }

}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>