<i18n>
{
  "en": {
    "backBtn": "Back to Dashboard",
    "settings": "Settings",
    "logout": "Log out"
  },
  "zh": {
    "backBtn": "返回儀表板",
    "settings": "设置",
    "logout": "登出"
  },
  "kr": {
    "backBtn": "대시보드로 돌아가기",
    "settings": "설정",
    "logout": "로그 아웃"
  }
}
</i18n>
<template>
  <div :class="{ dark: $store.state.Index.isDarkMode }">
    <div class="desktop-menu" :class="{ child: isChild }" v-if="!isMobile">

      <div class="back-btn" v-show="isChild">
        <router-link
            :to="$linkCreator($i18n.locale, 'dashboard')"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 5L7 10L12 15" stroke="#0A0F38" stroke-width="1.2"></path>
          </svg>
          <span>{{ $t('backBtn') }}</span>
        </router-link>
      </div>

      <div style="display: flex; flex-direction: column; height: 100%; overflow-y: auto;padding: 15px 16px 20px 16px;">
        <div>
          <DashboardMenuLink
              v-for="item, index in linkArray"
              :key="index"
              :icon="item.icon"
              :link="item.link"
              :name="item.title"
          />
        </div>

        <div style="margin-top: auto;" class="end-container">
          <a
              @click="logoutHandler"
              class="logout"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.50004 13.9489C3.50004 14.3527 3.16404 14.6804 2.75012 14.6804C2.3362 14.6804 2.00019 14.3527 2.00019 13.9489V6.71437C2.00019 4.39058 3.93831 2.5 6.32151 2.5H11.0675C13.4555 2.5 15.3985 4.39533 15.3985 6.72482V7.6093C15.3985 8.01212 15.0625 8.33988 14.6486 8.33988C14.2347 8.33988 13.8987 8.01212 13.8987 7.6093V6.72482C13.8987 5.20096 12.6287 3.96306 11.0675 3.96306H6.32151C4.76517 3.96306 3.50004 5.19811 3.50004 6.71437V13.9489ZM13.8985 16.3916C13.8985 15.9879 14.2345 15.6601 14.6484 15.6601C15.0623 15.6601 15.3983 15.9879 15.3983 16.3916V17.2856C15.3983 19.6094 13.4602 21.5 11.077 21.5H6.33008C3.94298 21.5 2 19.6047 2 17.2752C2 16.8714 2.336 16.5446 2.74992 16.5446C3.16384 16.5446 3.49985 16.8714 3.49985 17.2752C3.49985 18.799 4.76985 20.0369 6.33008 20.0369H11.077C12.6333 20.0369 13.8985 18.8028 13.8985 17.2856V16.3916ZM21.9433 11.7209C21.8274 11.4463 21.5537 11.2677 21.2499 11.2677H9.5491C9.13518 11.2677 8.7982 11.5964 8.7982 12.0002C8.7982 12.404 9.13518 12.7317 9.5491 12.7317H19.4345L17.8752 14.2461C17.5811 14.5311 17.5811 14.9938 17.8732 15.2807C18.0193 15.4241 18.2122 15.4963 18.404 15.4963C18.5959 15.4963 18.7868 15.4241 18.9339 15.2826L21.7797 12.5189C21.9939 12.3099 22.0592 11.9945 21.9433 11.7209ZM19.1218 10.6607C18.9309 10.6607 18.74 10.5895 18.594 10.4489L17.8762 9.7563C17.582 9.47223 17.5801 9.00862 17.8713 8.72265C18.1635 8.43384 18.6368 8.43194 18.9319 8.71695L19.6487 9.40858C19.9438 9.69359 19.9458 10.1563 19.6546 10.4432C19.5075 10.5876 19.3147 10.6607 19.1218 10.6607Z" fill="#808191"/>
            </svg>


            {{ $t('logout') }}
          </a>
        </div>
      </div>

    </div>
    <div class="mobile-menu" v-else>
      <DashboardMenuLink
          v-for="item, index in linkArray"
          :key="index"
          :icon="item.icon"
          :link="item.link"
          :name="item.title"
      />

      <div style="margin-top: auto;white-space: nowrap;" class="end-container">
        <a
            @click="logoutHandler"
            class="logout"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.50004 13.9489C3.50004 14.3527 3.16404 14.6804 2.75012 14.6804C2.3362 14.6804 2.00019 14.3527 2.00019 13.9489V6.71437C2.00019 4.39058 3.93831 2.5 6.32151 2.5H11.0675C13.4555 2.5 15.3985 4.39533 15.3985 6.72482V7.6093C15.3985 8.01212 15.0625 8.33988 14.6486 8.33988C14.2347 8.33988 13.8987 8.01212 13.8987 7.6093V6.72482C13.8987 5.20096 12.6287 3.96306 11.0675 3.96306H6.32151C4.76517 3.96306 3.50004 5.19811 3.50004 6.71437V13.9489ZM13.8985 16.3916C13.8985 15.9879 14.2345 15.6601 14.6484 15.6601C15.0623 15.6601 15.3983 15.9879 15.3983 16.3916V17.2856C15.3983 19.6094 13.4602 21.5 11.077 21.5H6.33008C3.94298 21.5 2 19.6047 2 17.2752C2 16.8714 2.336 16.5446 2.74992 16.5446C3.16384 16.5446 3.49985 16.8714 3.49985 17.2752C3.49985 18.799 4.76985 20.0369 6.33008 20.0369H11.077C12.6333 20.0369 13.8985 18.8028 13.8985 17.2856V16.3916ZM21.9433 11.7209C21.8274 11.4463 21.5537 11.2677 21.2499 11.2677H9.5491C9.13518 11.2677 8.7982 11.5964 8.7982 12.0002C8.7982 12.404 9.13518 12.7317 9.5491 12.7317H19.4345L17.8752 14.2461C17.5811 14.5311 17.5811 14.9938 17.8732 15.2807C18.0193 15.4241 18.2122 15.4963 18.404 15.4963C18.5959 15.4963 18.7868 15.4241 18.9339 15.2826L21.7797 12.5189C21.9939 12.3099 22.0592 11.9945 21.9433 11.7209ZM19.1218 10.6607C18.9309 10.6607 18.74 10.5895 18.594 10.4489L17.8762 9.7563C17.582 9.47223 17.5801 9.00862 17.8713 8.72265C18.1635 8.43384 18.6368 8.43194 18.9319 8.71695L19.6487 9.40858C19.9438 9.69359 19.9458 10.1563 19.6546 10.4432C19.5075 10.5876 19.3147 10.6607 19.1218 10.6607Z" fill="#808191"/>
          </svg>


          {{ $t('logout') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardMenuLink from "@/components/main/DashboardMenuLink";
import {mapState} from "vuex";
import axios from "../../plugins/axios";
import NavbarUserMenu from "@/components/main/NavbarUserMenu.vue";
import DarkModeToggle from "@/components/main/DarkModeToggle.vue";
import LocaleSwitcher from "@/components/main/LocaleSwitcher.vue";

export default {
  name: "dashboardMenu",
  components: {
    DashboardMenuLink
  },
  props: {
    isChild: Boolean,
    linkArray: Array
  },
  data() {
    return {
      isHamburgerClicked: false,
    }
  },
  methods: {
    async logoutHandler() {
      const response = await axios.post('/main/user/logout');
      if (!response.data?.error) return location.href = "/";
      this.$callNotification(
          this.$i18n.locale,
          response
      );
    },
  },
  computed: {
    ...mapState('Index', [
      'isMobile'
    ])
  }
}
</script>

<style lang="scss" scoped>

.dark {
  .desktop-menu {
    border-color: #363b3d !important;
    a {
      color: rgba(197, 193, 189, 0.6);
    }
    a.current {
      color: rgb(197, 193, 189);
    }
    a:hover {
      background-color: rgb(20, 37, 41);
      color: rgb(118, 142, 186) !important;
    }
    .back-btn {
      background-color: rgb(35, 38, 39);
      color: rgb(216, 214, 211) !important;

      a:hover {
        background-image: initial;
        background-color: transparent;
        color: rgb(192, 189, 182) !important;
      }
      a svg {
        fill: rgb(192, 189, 182);
      }
    }

  }
  .mobile-menu {
    background: #1c1e1f;
    color: rgb(216, 214, 211);
    border-color: #363b3d !important;

    a {
      background: transparent;
      color: rgba(197, 193, 189, 0.6);
    }
    a.current {
      color: rgb(197, 193, 189);
      background: #2A85FF;
    }
    a:hover {
      background-color: rgb(20, 37, 41);
      color: rgb(118, 142, 186) !important;
    }
    .back-btn {
      background-color: rgb(35, 38, 39);
      color: rgb(216, 214, 211) !important;

      a:hover {
        background-image: initial;
        background-color: transparent;
        color: rgb(192, 189, 182) !important;
      }
      a svg {
        fill: rgb(192, 189, 182);
      }
    }
  }
}

.back-btn {
  background-color: #edf0f5;
  padding: 8px 16px !important;
  color: #0a0f38;
  font-weight: 500;
  border-radius: 2px;
  transition: .3s;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: #0a0f38 !important;
    }
  }

  &:hover {
    background-color: #dde2ec;
  }
}

.desktop-menu {
  height: calc(100vh - 60px);
  border-right: 1px solid #edf0f5;
  border-top: 1px solid #edf0f5;

  &.child {
    height: calc(100vh - 108px) !important;
  }
}

.mobile-menu {

  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 998;
  display: flex;
  color: black;
  align-items: center;
  transition: 0.3s;
  border-bottom: 1px solid #edf0f5;
  background: #FFFFFF;
  overflow-x: auto;
  border-top: 1px solid #edf0f5;


  &::-webkit-scrollbar { display: none; }
}

a.logout {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(10,15,56,.6);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.41176rem;
  letter-spacing: .01em;
  border-radius: 10px;
  -webkit-transition: .25s ease-out;
  -o-transition: .25s ease-out;
  transition: .25s ease-out;
  text-decoration: none;
  outline: 0;
  margin: 4px 0px;
  cursor: pointer;

  &:hover {
    background-color: #2A85FF;
    color: white !important;

    svg {
      filter: brightness(0) invert(1);
    }
  }

  &.current {
    color: #0a0f38;
  }

  &.mobile {
    margin: 4px 12px;
    padding: 5px 10px;
  }

}

svg {
  display: flex;
  width: 30px;
  height: 30px;
  position: relative;
  top: 2px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.mobile .menu {
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 3999;
  height: 100vh;
  width: 280px;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 2px 32px 0 rgba(0,0,0,0.15);
  padding: 70px 20px 0;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);

  &.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }

  &.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@-webkit-keyframes slide-out {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(-100%); }
}

.mobile .section {
  padding: 10px 0 20px;
  border-bottom: 1px solid #bebebe;
}

.mobile .section:nth-child(2) {
  padding: 10px 0 10px;
}

.mobile .section:nth-child(3) {
  padding: 20px 0 10px;
  border-bottom: none;
}

.mobile .section ul {
  display: block;
}

.hamburger-input {
  opacity: 0;
}

.hamburger-input:checked ~ .hamburger-button > span {
  transform: rotate(45deg);
}
.hamburger-input:checked ~ .hamburger-button > span::before {
  top: 0;
  transform: rotate(0);
}
.hamburger-input:checked ~ .hamburger-button > span::after {
  top: 0;
  transform: rotate(90deg);
}

.hamburger-button {
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 4000;
  width: 26px;
  height: 26px;
  cursor: pointer;

}

.hamburger-button > span,
.hamburger-button > span::before,
.hamburger-button > span::after {
  display: block;
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #616161;

  transition-duration: .25s;
}
.hamburger-button > span::before {
  content: '';
  top: -8px;
}
.hamburger-button > span::after {
  content: '';
  top: 8px;
}

</style>