<i18n>
{
  "en": {
    "sections": [
      {
        "title": "Company",
        "links": [
          {
            "title": "Terms of use",
            "link": "terms"
          },
          {
            "title": "Privacy",
            "link": "privacy"
          }
        ]
      },
      {
        "title": "Products",
        "links": [
          {
            "title": "Exchange",
            "link": "exchange"
          },
          {
            "title": "Staking",
            "link": "dashboard/staking"
          },
          {
            "title": "Listing",
            "link": "listing-with-us"
          },
          {
            "title": "NFT",
            "link": "nft",
            "disabled": true
          }
        ]
      },
      {
        "title": "Information",
        "links": [
          {
            "title": "Referral",
            "link": "dashboard/referral"
          },
          {
            "title": "Fees",
            "link": "fees"
          },
          {
            "title": "F.A.Q.",
            "link": "faq"
          }
        ]
      }
    ],
    "copyright": "Copyright 2017-{year} © {domain} All Rights Reserved"
  },
  "zh": {
    "sections": [
      {
        "title": "公司",
        "links": [
          {
            "title": "使用条款",
            "link": "terms"
          },
          {
            "title": "隐私",
            "link": "privacy"
          }
        ]
      },
      {
        "title": "产品",
        "links": [
          {
            "title": "交换",
            "link": "exchange"
          },
          {
            "title": "質押",
            "link": "dashboard/staking"
          },
          {
            "title": "清单",
            "link": "listing-with-us"
          },
          {
            "title": "NFT",
            "link": "nft",
            "disabled": true
          }
        ]
      },
      {
        "title": "信息",
        "links": [
          {
            "title": "轉介",
            "link": "dashboard/referral"
          },
          {
            "title": "费率",
            "link": "fees"
          },
          {
            "title": "F.A.Q.",
            "link": "faq"
          }
        ]
      }
    ],
    "copyright": "版权 2017-{year} © {domain} 版权所有"
  },
  "kr": {
    "sections": [
      {
        "title": "회사",
        "links": [
          {
            "title": "이용 약관",
            "link": "terms"
          },
          {
            "title": "개인 정보 정책",
            "link": "privacy"
          }
        ]
      },
      {
        "title": "제품",
        "links": [
          {
            "title": "교환",
            "link": "exchange"
          },
          {
            "title": "스테이 킹",
            "link": "dashboard/staking"
          },
          {
            "title": "목록",
            "link": "listing-with-us"
          },
          {
            "title": "NFT",
            "link": "nft",
            "disabled": true
          }
        ]
      },
      {
        "title": "정보",
        "links": [
          {
            "title": "추천",
            "link": "dashboard/referral"
          },
          {
            "title": "수수료",
            "link": "fees"
          },
          {
            "title": "F.A.Q.",
            "link": "faq"
          }
        ]
      }
    ],
    "copyright": "저작권 2017-{year} © {domain} 판권 소유"
  }
}
</i18n>
<template>
  <footer :class="{ 'dashboard': isDashboard, 'dark': $store.state.Index.isDarkMode }" v-if="$store.state.Index.customPageSettings.defaultFooter">
    <div class="container">
      <div class="sections">
        <div
            style="margin-bottom: 40px;" class="col-xs-12"
            :class="{
              'col-lg-12': isDashboard,
              'col-lg-4': !isDashboard,
              'col-md-12': isDashboard,
              'col-md-5': !isDashboard
            }"
        >
          <div
              class="section col-lg-4 col-md-4 col-xs-6"
              v-for="section, index in $t('sections')"
              :key="index"
          >
            <p>{{ section.title }}</p>
            <router-link
                v-for="link, secondIndex in section.links"
                :key="secondIndex+link.link"
                :to="link.disabled ? '#' : $linkCreator($i18n.locale, link.link)"
                :class="{  disableded: link.disabled, disabled: link.disabled }"
            >
              {{ link.title }} <span class="badge badge-warning" v-show="link.disabled">Soon</span>
            </router-link>
          </div>
        </div>
        <div
            class="partners col-xs-12"
            :class="{
              'col-lg-12': isDashboard,
              'col-lg-6': !isDashboard,
              'col-md-12': isDashboard,
              'col-md-6': !isDashboard
            }"
        >
          <div
              class="partner col-lg-3 col-md-4 col-xs-6"
              :key="index"
              v-for="img, index in 18"
          >
            <div class="img">
              <img :src="`/partners/${index+1}.png`" >
            </div>
          </div>
        </div>
        <div style="clear: both;"></div>
      </div>
      <hr size="1" color="#696969">
      <div class="copyright">
        {{ $t('copyright', { domain: websiteName.full, year: new Date().getFullYear() }) }}
      </div>
    </div>
  </footer>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BottomBar",
  props: {
    isDashboard: Boolean
  },
  computed: {
    ...mapState('Index', [
      'websiteName'
    ])
  }
}
</script>

<style lang="scss" scoped>

footer {

  &.dashboard {

    background: #fafafa;
    color: black !important;

    .section {
      p {
        color: black !important;
      }
      a {
        color: #707a8a !important;

        &.disabled {
          color: #d4d4d4 !important;
        }
      }
    }
    .partners {
      .img {
        filter: invert(90%) sepia(91%) saturate(38%) hue-rotate(254deg) brightness(110%) contrast(110%);
      }
    }

    &.dark {
      background: #1d1f20;
      color: white !important;

      .section {
        p {
          color: white !important;
        }
        a {
          color: #7f8288 !important;

          &.disabled {
            color: #3e3e3e !important;
          }
        }
      }
      .partners {
        .img {
          filter: invert(0%) sepia(55%) saturate(2%) hue-rotate(352deg) brightness(108%) contrast(101%)
        }
      }
    }

    @media (max-width: 992px) {
      a.item, .section {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    @media (min-width: 992px) {
      .container {
        width: calc(970px - 360px) !important;
      }
    }

    @media (min-width: 768px) {
      .container {
        width: calc(970px - 360px) !important;
      }
    }

    @media (min-width: 1200px) {
      .container {
        width: calc(1170px - 360px) !important;
      }
    }
  }

  background-image : linear-gradient(to bottom, #04091c, #03081a, #030818, #020716, #020614);

  .sections {
    padding: 60px 0 30px;
    box-sizing: border-box;

    .section {
      margin-bottom: 10px;
      p {
        color: white;
        font-weight: 800;
        margin-bottom: 12px;
      }
      a {
        color: #9d9d9d;
        display: block;
        transition: color .2s ease-in-out;
        line-height: 36px;
      }
    }
    .partners {
      float: right;

      .partner {
        height: 50px;

        div.img {
          width: 100px;
          transition: .2s;

          img {
            width: 100%;
          }
        }
      }

      img:hover {
        opacity: .5;
      }
    }
  }

  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #707070;
    font-size: 14px;
    line-height: 64px;
  }

}

footer .sections .section a.disabled {
  color: #464444;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  font-size: 8px;
  position: relative;
  top: -5px;
  left: 2px;
}
</style>