var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.Index.customPageSettings.defaultFooter)?_c('footer',{class:{ 'dashboard': _vm.isDashboard, 'dark': _vm.$store.state.Index.isDarkMode }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"sections"},[_c('div',{staticClass:"col-xs-12",class:{
            'col-lg-12': _vm.isDashboard,
            'col-lg-4': !_vm.isDashboard,
            'col-md-12': _vm.isDashboard,
            'col-md-5': !_vm.isDashboard
          },staticStyle:{"margin-bottom":"40px"}},_vm._l((_vm.$t('sections')),function(section,index){return _c('div',{key:index,staticClass:"section col-lg-4 col-md-4 col-xs-6"},[_c('p',[_vm._v(_vm._s(section.title))]),_vm._l((section.links),function(link,secondIndex){return _c('router-link',{key:secondIndex+link.link,class:{  disableded: link.disabled, disabled: link.disabled },attrs:{"to":link.disabled ? '#' : _vm.$linkCreator(_vm.$i18n.locale, link.link)}},[_vm._v(" "+_vm._s(link.title)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(link.disabled),expression:"link.disabled"}],staticClass:"badge badge-warning"},[_vm._v("Soon")])])})],2)}),0),_c('div',{staticClass:"partners col-xs-12",class:{
            'col-lg-12': _vm.isDashboard,
            'col-lg-6': !_vm.isDashboard,
            'col-md-12': _vm.isDashboard,
            'col-md-6': !_vm.isDashboard
          }},_vm._l((18),function(img,index){return _c('div',{key:index,staticClass:"partner col-lg-3 col-md-4 col-xs-6"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":("/partners/" + (index+1) + ".png")}})])])}),0),_c('div',{staticStyle:{"clear":"both"}})]),_c('hr',{attrs:{"size":"1","color":"#696969"}}),_c('div',{staticClass:"copyright"},[_vm._v(" "+_vm._s(_vm.$t('copyright', { domain: _vm.websiteName.full, year: new Date().getFullYear() }))+" ")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }